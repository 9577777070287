const RiskScore = ({ num, text1, text2, text3, hrLine = false }) => {
  return (
    <>
      {num && (
        <p className="font-inter text-[80px] font-bold leading-[88px]">{num}</p>
      )}
      {text1 && <p className="font-inter text-[21px] font-semibold">{text1}</p>}
      <p className="border-dotted border-t-2 border-white my-6"></p>
      {text2 && <p className="font-inter text-[18px] font-bold">{text2}</p>}
      {text3 && <p className="font-inter text-[18px] font-bold">{text3}</p>}
    </>
  );
};

export default RiskScore;
